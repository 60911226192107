var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",staticStyle:{"text-align":"left"}},[_c('Breadcrumb',{staticClass:"custom-breadcrumb"},[_c('BreadcrumbItem',[_vm._v("应试管理")]),_c('BreadcrumbItem',[_vm._v("批阅")])],1),_c('div',{staticClass:"table"},[_c('Row',{attrs:{"gutter":15}},[_c('Col',{attrs:{"span":"6"}},[_c('Table',{staticClass:"left",attrs:{"columns":_vm.qColumn,"data":_vm.examData,"stripe":"","loading":_vm.examLoading},on:{"on-row-click":_vm.getList},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])})],1),_c('Col',{attrs:{"span":"18"}},[_c('div',{staticClass:"table-container bg-shadow"},[_c('Table',{staticClass:"right",attrs:{"columns":_vm.columns,"data":_vm.questionsData,"stripe":"","loading":_vm.loading},on:{"on-row-click":_vm.toMark},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.candidateName))])]}},{key:"department",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.departmentName))])]}},{key:"time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.timeStamp(row.duration)))])]}},{key:"startTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"endTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.submitTime, 'yyyy-MM-dd hh:mm')))])]}}])}),_c('div',{staticClass:"page"},[_c('div',{staticStyle:{"float":"right"}},[_c('Page',{attrs:{"show-total":"","show-sizer":"","show-elevator":"","total":_vm.total,"current":_vm.page + 1},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changePageSize}})],1)])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }