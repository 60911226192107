<template>
  <div class="content" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>应试管理</BreadcrumbItem>
      <BreadcrumbItem>批阅</BreadcrumbItem>
    </Breadcrumb>
    <div class="table">
      <Row :gutter="15">
        <Col span="6">
          <Table :columns="qColumn" :data="examData" stripe class="left" @on-row-click="getList" :loading="examLoading">
            <template slot-scope="{ row, index }" slot="name">
              <span>{{ row.name }}</span>
            </template>
          </Table>
        </Col>
        <Col span="18">
          <div class="table-container bg-shadow">
            <Table :columns="columns" :data="questionsData" stripe class="right" :loading="loading" @on-row-click="toMark">
              <template slot-scope="{ row, index }" slot="name">
                <span>{{ row.candidateName }}</span>
              </template>
              <template slot-scope="{ row, index }" slot="department">
                <span>{{ row.departmentName }}</span>
              </template>
              <template slot-scope="{ row, index }" slot="time">
                <span>{{ timeStamp(row.duration) }}</span>
              </template>
              <template slot-scope="{ row, index }" slot="startTime">
                <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
              </template>
              <template slot-scope="{ row, index }" slot="endTime">
                <span>{{ $formatTime(row.submitTime, 'yyyy-MM-dd hh:mm') }}</span>
              </template>
            </Table>
            <div class="page">
              <div style="float: right;">
                <Page
                  show-total
                  show-sizer
                  show-elevator
                  :total="total"
                  :current="page + 1"
                  @on-change="changePage"
                  @on-page-size-change="changePageSize"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import examApi from '@api/exam'
export default {
  name: 'Marking',
  data() {
    return {
      qColumn: [
        {
          title: '考试名称',
          slot: 'name'
        }
      ],
      columns: [
        {
          title: '姓名',
          slot: 'name',
          className: 'name-column'
        },
        {
          title: '部门',
          slot: 'department'
        },
        {
          title: '用时',
          slot: 'time'
        },
        {
          title: '开始时间',
          slot: 'startTime',
          className: 'time-column'
        },
        {
          title: '提交时间',
          slot: 'endTime',
          className: 'time-column'
        }
      ],
      page: 0,
      size: 10,
      total: 0,
      examData: [],
      questionsData: [],
      examLoading: false,
      loading: false,
      examId: '',
      examName: ''
    }
  },
  created() {
    this.examLoading = true
    examApi.getMarkingPapers().then(res => {
      this.examData = res.res
      this.examLoading = false
      if (res.res.length > 0) {
        this.getList(res.res[0])
      }
    })
  },
  methods: {
    timeStamp(second_time) {
      let time = parseInt(second_time) + '秒'
      if (parseInt(second_time) > 60) {
        let second = parseInt(second_time) % 60
        let min = parseInt(second_time / 60)
        time = min + '分' + second + '秒'

        if (min > 60) {
          min = parseInt(second_time / 60) % 60
          let hour = parseInt(parseInt(second_time / 60) / 60)
          time = hour + '小时' + min + '分' + second + '秒'

          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24
            let day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
            time = day + '天' + hour + '小时' + min + '分' + second + '秒'
          }
        }
      }
      return time === 'NaN秒' ? '' : time
    },
    toMark(row) {
      this.$router.push({
        path: '/candidate/markQuestions',
        query: { name: this.examName, id: row.answerPaperId }
      })
    },
    getList(row) {
      this.examId = row.examId
      this.page = 0
      this.size = 10
      this.examName = row.name
      this.initData()
    },
    initData() {
      this.loading = true
      examApi.getMarkingList(this.examId, this.page, this.size).then(res => {
        this.questionsData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    changePage(val) {
      this.page = val - 1
      this.initData()
    },
    changePageSize(val) {
      this.size = val
      this.initData()
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.table {
  margin-top: 10px;
  height: 100%;
}
.table-container {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .right {
    border-bottom: 1px solid #e8eaec;

    .name-column {
      width: 10%;
    }

    .time-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .operation-column {
      width: 15%;
    }
    .ivu-table-wrapper {
      height: 100%;
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 10px;
    font-size: 14px;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
::v-deep .ivu-row {
  height: 100%;
}
::v-deep .ivu-col {
  height: 100%;
}
::v-deep .ivu-table-wrapper {
  height: 100%;
}
</style>
